import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyATXeDZufI3fWqQHkZ4rVdSp7utvo7Teoc",
  authDomain: "live-gogiri-app.firebaseapp.com",
  projectId: "live-gogiri-app",
  storageBucket: "live-gogiri-app.appspot.com",
  messagingSenderId: "290666593391",
  appId: "1:290666593391:web:a09e5c6974dda52216219a",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export default db
