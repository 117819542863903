import React from "react";
import YouTube from "react-youtube";
import styled from "styled-components";

const ContainerYt = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
`;

const YoutubeDiv = styled(YouTube)`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

function Youtube({ youtubeData }) {
      const opts = {
        width: "100%",
        height: "100%",
        title: youtubeData.title,
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
          allow:
            "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;fullscreen *",
        },
      };
  return (
    <ContainerYt>
      <YoutubeDiv videoId={youtubeData.ID} opts={opts} />
      {/* <IframeYt
        width="632"
        height="356"
        src={youtubeURL}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></IframeYt> */}
    </ContainerYt>
  );
}

export default Youtube;
