import React from "react";
import styled from 'styled-components';
import YouTube from 'react-youtube';

const ContainerYtchat = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  `
const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

function ChatYoutube({ youtubeData }) {
  const youtubeChatURL =
    "https://www.youtube.com/live_chat?v=" +
    youtubeData.ID +
    "&embed_domain=livegogiri.com"

  return (
    <ContainerYtchat>
      <Iframe
        width="100%"
        height="auto"
        title={youtubeData.title}
        src={youtubeChatURL}
        frameborder="0"
        scrolling="no"
      ></Iframe>
    </ContainerYtchat>
  );
}

export default ChatYoutube;
