import { useEffect, useState } from "react";
import styled from "styled-components";
import {doc, getDoc, onSnapshot } from "firebase/firestore";
import db from "./firebase";
import UnderColumn from "./components/UnderColumn";
import Youtube from "./components/Youtube";


const ContainerMain = styled.div`
  height: 100%;
  flex: 1;
  margin: 0;
  padding: 0 0 30px 0;
`;

function App() {
  const [bigiriData, setBigiriData] = useState([]);
  const [youtubeData, setYoutubeData] = useState([]);

  useEffect(() => {
    //データベースからbigiriデータを取得する
    const bigiriDb = doc(db, "bigiri", "first");
    getDoc(bigiriDb).then((docSnap) => {
      setBigiriData({ ...docSnap.data() });
    });
    //リアルタイム更新
    onSnapshot(bigiriDb, (docSnap) => {
      setBigiriData({ ...docSnap.data() });
    });
    //データベースからyoutubeデータを取得する
    const youtubeDb = doc(db, "youtube", "first");
    getDoc(youtubeDb).then((docSnap) => {
      setYoutubeData({ ...docSnap.data() });
    });
    //リアルタイム更新
    onSnapshot(youtubeDb, (docSnap) => {
      setYoutubeData({ ...docSnap.data() });
    });
  }, []);

  return (
    <ContainerMain>
      <Youtube youtubeData={youtubeData} />
      <UnderColumn youtubeData={youtubeData} bigiriData={bigiriData} />
    </ContainerMain>
  );
}

export default App;
