import React from "react";
import { useState } from "react";
import styled from "styled-components";
import chatIcon from "../items/icon-hukidasi-up.png";
import chatIconClose from "../items/icon-hukidasi-up-close.png";
import Bigiri from "./under-column/Bigiri";
import ChatYoutube from "./under-column/ChatYoutube";

const ContainerU = styled.div`
  position: relative;
  height: 100%;
  height: calc(99vh - (100vw * 0.5625));
  /* height: ${window.innerHeight - (window.innerWidth * 0.5625)}px; */
  width: 100%;
  left: 0;
  color: #ffffff;
  border: none;
  background: #c0c0c0;
`;

const ChatIcon = styled.div`
  position: absolute;
  top: -7px;
  left: 2px;
  z-index: 120;
  cursor: pointer;
  & img {
    width: calc(100vh - (100vh * 0.93));
    height: calc(100vh - (100vh * 0.93));
  }
`;


const ContainerChat = styled.div`
  width: 100%;
  height: calc(98vh - (100vw * 0.5625));
  position: absolute;
  bottom: 3px;
  left: ${(props) => (props.chat === "chat-on" ? "0" : "-120%")};
  color: blue;
  z-index: 10;
  transition: all 0.6s;
`;

function UnderColumn( { youtubeData, bigiriData } ) {
  const [tab, setTab] = useState("chat-off");
  const [icon, setIcon] = useState(chatIcon);

  const handleChange = () => {
    if (tab === "chat-off") {
      setTab("chat-on");
      setIcon(chatIconClose);
    } else if (tab === "chat-on") {
      setTab("chat-off");
      setIcon(chatIcon);
    }
  };

  return (
    <ContainerU>
      <ChatIcon chat={tab} onClick={(e) => handleChange()}>
        <img alt="" src={icon}></img>
      </ChatIcon>
      <Bigiri bigiriData={bigiriData} />
      <ContainerChat chat={tab}>
        <ChatYoutube youtubeData={youtubeData} />
      </ContainerChat>
    </ContainerU>
  );
}

export default UnderColumn;
