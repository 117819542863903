import React from "react";
import styled from "styled-components";

const ContainerBi = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
const IframeBi = styled.iframe`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

function Bigiri({ bigiriData }) {

  return (
    <ContainerBi>
      <IframeBi
        width="100%"
        src= {bigiriData.link}
        title={bigiriData.title}
        frameborder="0"
      ></IframeBi>
    </ContainerBi>
  );
}

export default Bigiri;
